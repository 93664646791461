export {};

// See webpack.md for help. This MUST be included in any module that performs dynamic imports.

declare global {
  /**
   * This is a global that is defined in a liquid snippet to point to the Shopify asset path.
   * Webpack compilation is transpiled to point the variable to the global. Because Webpack
   * compilation involves raw string replacement, this variable has to actually exist. Because we
   * do not know the public path at compile time this is basically a hack that allows to define the
   * public path at runtime. This public path is needed for webpack to perform its dynamic module
   * loading, e.g. to support dynamic imports.
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var __webpack_public_path__: string;
}

__webpack_public_path__ = window.__webpack_public_path__;
