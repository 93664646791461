import '../../lib/webpack-path.js';
import { appendCSSText } from '../../lib/css-dom.js';
import { retry } from '../../lib/promise-retry.js';
import styles from './index.scss';

async function init() {
  const carousels = document.querySelectorAll<HTMLElement>('.splide-why-we-are-trusted');

  // We must still perform this check because if Marketing has not configured more than three icons
  // then we should not be displaying a carousel. In these use cases the above query will return
  // undefined and we should short circuit.
  if (carousels.length === 0) {
    return;
  }

  const { Splide } = await retry(import('@splidejs/splide'));

  for (const carousel of carousels) {
    const splide = new Splide(carousel, {
      type: 'loop',
      role: 'group',
      arrows: true,
      pagination: true,
      perPage: 1,
      perMove: 1,
      padding: 0,
      lazyload: 'nearby',
      keyboard: true,
      mediaQuery: 'min',
      breakpoints: {
        540: {
          perPage: 2,
          perMove: 2
        },
        768: {
          perPage: 3,
          perMove: 1
        },
        1280: {
          arrows: false,
          destroy: true
        }
      }
    });

    splide.mount();
  }
}

function onDOMContentLoaded(_event: Event) {
  init().catch(console.warn);
}

declare global {
  /**
   * Guards against double initialization.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-var
  var banner_why_we_are_trusted_section_loaded: boolean;
}

if (!window.banner_why_we_are_trusted_section_loaded) {
  appendCSSText(styles);

  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    setTimeout(onDOMContentLoaded);
  } else {
    addEventListener('DOMContentLoaded', onDOMContentLoaded);
  }

  window.banner_why_we_are_trusted_section_loaded = true;
}
